
export default {
  name: 'TemplatesPagesHome',
  data() {
    return {
      initialLoaded: false,
      latest: [],
      recommended: []
    }
  },
  mounted() {
    this.fetchEvents()
  },
  methods: {
    async fetchEvents() {
      try {
        const latest = await this.$axios.$get(`/api/event`, {
          params: {
            recent: 1,
            limit: 8
          }
        })

        this.latest = latest.data

        const recommended = await this.$axios.$get(`/api/event`, {
          params: {
            recommended: 1,
            limit: 12
          }
        })

        this.recommended = recommended.data
      } catch (e) {
        console.log(e)
      } finally {
        this.initialLoaded = true
      }
    }
  }
}
