
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
export default {
  name: 'TemplatesSectionsSpotlight',
  components: {
    VueSlickCarousel
  },
  data() {
    return {
      spotlight: [],
      settings: {
        arrows: false,
        dots: true,
        fade: true,
        autoplay: true
      }
    }
  },
  fetchOnServer: false,
  async fetch() {
    try {
      const { data } = await this.$axios.$get('/api/event', {
        params: {
          spotlight: 1
        }
      })
      this.spotlight = data
    } catch (e) {
      console.log(e)
    }
  }
}
