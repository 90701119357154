
export default {
  props: {
    skeleton: {
      type: Boolean,
      default: false
    },
    imgAlt: {
      type: String,
      default: null
    },
    imgBase: {
      type: String,
      default: null
    }
  }
}
