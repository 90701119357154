import { render, staticRenderFns } from "./Spotlight.vue?vue&type=template&id=78f8325b&scoped=true&"
import script from "./Spotlight.vue?vue&type=script&lang=js&"
export * from "./Spotlight.vue?vue&type=script&lang=js&"
import style0 from "./Spotlight.vue?vue&type=style&index=0&id=78f8325b&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78f8325b",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ElementsHero: require('/home/ticketshoplv/public_html/ticketshop.lv/components/elements/Hero.vue').default})
