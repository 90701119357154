
export default {
  props: {
    loadingItems: {
      type: Number,
      default: 8
    },
    loading: {
      type: Boolean,
      default: false
    },
    events: {
      type: Array,
      default: () => []
    }
  }
}
